<template>
    <div class="grid lg:grid-cols-3 gap-5">
        <FormInputField v-model="trackingForm.code" placeholder="Tacking Code" />
        <FormSelectField v-model="trackingForm.provider" :select-data="trackingProvider" keyvalue="value" labelvalue="name" placeholder="Provider" />
        <div>
            <button class="btn-rounded btn btn-dark px-3 w-full gap-5" @click="applyTracking">
                <Icon class="size-5" name="ph:magnifying-glass-duotone" />
                <span>Track Your Shipment</span>
            </button>
        </div>
    </div>
</template>

<script setup>
const trackingProvider = ref([
    {
        name: 'DHL',
        value: 'dhl',
        url: 'https://www.dhl.com/global-en/home/tracking/tracking-express.html?submit=1&tracking-id=',
    },
    { name: 'UPS', value: 'ups', url: 'https://www.ups.com/track?loc=en_US&requester=WT&tracknum=' },
    {
        name: 'TNT',
        value: 'tnt',
        url: 'https://www.tnt.com/express/en_gb/site/shipping-tools/tracking.html?source=public_menu&searchType=CON&commit=Track!&cons=',
    },
    { name: 'Fedex', value: 'fedex', url: 'https://www.fedex.com/fedextrack/system-error?trknbr=' },
    {
        name: 'DB Scchenker',
        value: 'db-scchenker',
        url: 'https://eschenker.dbschenker.com/app/tracking-public/?commit=Track!&language_region=en-US_US&refNumber=',
    },
    {
        name: 'DHL G.F.',
        value: 'dhl-g-f',
        url: 'https://dhli.dhl.com/dhli-client/publicTrackingSummary?1&searchType=HBN&commit=Track!&searchValue=',
    },
    {
        name: 'Container',
        value: 'container',
        url: 'https://www.searates.com/container/tracking/?sealine=AUTO&container=',
    },
]);
const trackingForm = ref({
    code: null,
    provider: null,
});
const applyTracking = () => {
    if (trackingForm.value.code && trackingForm.value.provider) {
        const selectedProvider = trackingProvider.value.find((provider) => provider.value === trackingForm.value.provider);
        if (selectedProvider) {
            const trackingUrl = selectedProvider.url + trackingForm.value.code;
            window.open(trackingUrl, '_blank');
        }
    } else {
        useToast({ title: 'Error', message: 'Please fill all required fields', type: 'error', duration: 5000 });
    }
};
</script>
